var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.load,"rounded":"sm"}},[_c('div',{staticClass:"container_site",staticStyle:{"padding-bottom":"30px"}},[_c('div',{staticClass:"ubication_dimension",staticStyle:{"padding-bottom":"30px"}},[_c('p',{staticClass:"ubication_dimension--home",on:{"click":function($event){return _vm.getRoute('Home')}}},[_vm._v(" Inicio ")]),_c('img',{staticClass:"ubication_dimension--breadcrumb",attrs:{"src":require("@/assets/img/arrow-breadcrumb.svg")}}),_c('p',{staticClass:"ubication_dimension--name_dimension"},[_vm._v(" "+_vm._s(_vm.article.title)+" ")])]),_c('div',{staticClass:"container_dimension"},[_c('div',{staticClass:"container_dimension__content"},[_c('div',{staticClass:"container_dimension__content--card"},[_c('div',{staticClass:"container_dimension__content--imgDimension",style:({
                'background-image': ("url(" + (_vm.article.thumbnail) + ")"),
                'background-size': 'cover',
                'background-position': 'center',
                'max-width': '500px',
                'max-height': '80vh',
                'width': '30%'
              })}),_c('div',{staticClass:"container_dimension__content--description",style:({ 'background-color': _vm.getClient().primary_color })},[_c('div',{staticClass:"container_dimension__content--title"},[_vm._v(" "+_vm._s(_vm.article.title)+" "),_c('br'),_c('small',{staticStyle:{"font-size":"14px","margin-top":"-10px"}},[_vm._v("Publicado el "+_vm._s(_vm.article.publication_date))])]),_c('div',{staticClass:"container_dimension__content--text"})])]),_c('div',{staticClass:"container_dimension__content--segments"},[_c('div',{staticClass:"container_dimension__content--description_segment",staticStyle:{"border-radius":"5px !important"},domProps:{"innerHTML":_vm._s(_vm.article.description)}})])])]),_c('div',{staticClass:"container_dimension_mobile mb-5",style:({ 'border-top': '10px solid ' + _vm.getClient().primary_color })},[_c('div',{staticClass:"container_dimension_mobile--header_dimension",style:({
            'background-image': ("url(" + (_vm.article.thumbnail) + ")"),
            'background-size': 'cover',
            'background-position': 'center',
          })}),_c('div',{staticClass:"container_dimension_mobile--title_dimension"},[_vm._v(" "+_vm._s(_vm.article.title)+" ")]),_c('small',{style:({ padding: '15px' })},[_vm._v("Publicado: "+_vm._s(_vm.article.publication_date))]),_c('div',{staticClass:"container_dimension_mobile__card_segments"},[_c('div',{staticClass:"container_dimension_mobile__card_segment",style:({
              'border-top': '10px solid ' + _vm.getClient().primary_color,
            })},[_c('div',{staticClass:"container_dimension_mobile__card_segment--description_segment",domProps:{"innerHTML":_vm._s(_vm.article.description)}})])])]),_c('div',{staticClass:"container_btn_actions mb-5"},[_c('div',{staticClass:"container_btn_actions__btn_back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"src":require("@/assets/img/icono-regresar.svg"),"alt":""}}),_c('p',[_vm._v("Regresar")])]),_c('div',{staticClass:"container_btn_actions__btn_home",on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('img',{attrs:{"src":require("@/assets/img/icono-inicio.svg"),"alt":""}}),_c('p',[_vm._v("Inicio")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }